
.hover-list:hover {
    cursor: pointer;
    background-color: #fafafa;
  }
  
  .custom-map-modal.modal-dialog {
      width: 100%;
      max-width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
  }
  
  .custom-map-modal .modal-content {
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 0;
  }
  
  .page-item.active .page-link {
    background-color: #F85C46;
    border-color: #F85C46;
  }
  
  .nav-pills .nav-link {
    color: #F85C46;
    background-color: #fff;
  }
  .nav-pills .nav-link:hover {
    color: #F85C46;
    background-color: #efefef;
  }
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #F85C46;
  }
  
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #F85C46; }
  
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    background-color: #F85C46;
    box-shadow: none; }
  
  .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(248, 92, 70, 0.5);
  }
  
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #F85C46;
    box-shadow: none; }
  
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #F85C46; }
  
  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
    border-left: 2px solid #F85C46;}
  
  /*.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active{
    border-right: 2px solid #F85C46; }*/
  .hover-link:hover {
    color: #888;
  }
  
  .white-link {
    color: #fff;
  }
  
  .white-link:hover {
    color: #eee;
  }
  
  
  @-webkit-keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
  }
  
  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }
  
  @-webkit-keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      opacity: 1;
    }
  }
  
  @keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      opacity: 1;
    }
  }
  
  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite;
  }
  
  .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
  }
  
  .load-opacity {
    opacity: 0.5;
  }
  
  .date-filter {
    width: 112px;
    height: 31px;
  }
  
  .date-width {
    height: 31px;
  }
  
  .rdtPicker {
    width: 288px;
    left: 0;
  }
  
  @media (min-width: 576px) {
    .mobile-flex {
      display: flex;
    }
  }
  
  @media (max-width: 576px) {
    .hide-mobile {
      display: none;
    }
  }
  
  .mapContainer {
    position:absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
  }
  
  .map-pop {
    margin: 0;
    font-weight: bold;
    font-size: 12px;
  }
  
  .map-list {
    cursor: pointer;
    padding: 7px 10px;
    margin: 3px -10px;
  }
  
  .map-list:hover {
    background-color: #eee;
    border-radius: 5px;
  }
  
  .map-list:hover > p {
    color: #EC3237;
  }
  
  .map-list > p {
    margin: 0;
    font-size: 11.5px;
    font-weight: 400;
    line-height: 1.4;
  }
  
  .mapboxgl-popup-content {
    padding: 5px 10px;
  }
  
  .rdtOpen.date-input .rdtPicker {
      right: -70px;
      left: unset;
  }
  
  .rdtOpen.date-input .rdtPicker:after {
      top: unset;
  }
  
  .rdtOpen.date-input .rdtPicker:before {
      top: unset;
  }
  
  .react-datepicker__input-container {
    width: 105px;
  }
  
  .react-datepicker__input-container > input {
    width: 105px;
    border-radius: 5px;
    font-size: 15px;
    padding: 4px 7px;
    border: 0.5px #ddd solid;
  }
  
  .react-datepicker__input-container > input:after {
    content: 'to';
  }
  
  .search-input {
    min-width: 130px
  }
  
  .rc-select-open.search-input {
    border: 1px #eee solid;
    border-radius: 5px;
    height: 28px
  }
  
  .hover-icon {
    cursor: auto;
  }
  
  .hover-icon > i {
    font-size: 17px;
    cursor: pointer;
  }
  
  .hover-icon > i:hover {
    color: #EC3237;
  }
  
  .broadcast {
    background-color: #EC3237;
    margin-top: 10px;
    margin-left: -5px;
    cursor: pointer;
    padding: 7px 10px 7px 15px;
    border-radius: 5px;
    color: #fff;
  }
  
  .broadcast-on > p {
    transition: transform 0.4s ease-in-out;
  }
  
  .broadcast-on:hover > p {
    transform: scale(1.07);
  }
  
  .form-date .rdt {
    width: 100%;
  }
  
  .form-date .rdt > input{
    width: 100%;
  }
  